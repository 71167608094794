import styled, { css } from 'styled-components'

import { IconClose as IconCloseBase } from '~/components/icons/close'
import { mq } from '~/styles/mq'
import { theme } from '~/styles/theme'
import { heading, label } from '~/styles/typography'

import { Button } from '../button'
import { RadioList } from '../form-field/radio-list'

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9000;
  transition: opacity 0.3s;
  background-color: rgba(0, 0, 0, 0.5);

  &.enter {
    opacity: 0;
  }

  &.enter-active {
    opacity: 1;
  }

  &.exit {
    opacity: 1;
  }

  &.exit-active {
    opacity: 0;
  }
`

export const CloseButton = styled.button`
  background-color: transparent;
  border: none;
  display: block;
  margin: 0.5rem 0.5rem 0 auto;
  padding: 1rem;
  color: inherit;
  z-index: 1;

  ${mq.medium} {
    margin: 1.5rem 1.5rem 1rem auto;
  }

  &:hover {
    cursor: pointer;
  }
`

export const Container = styled(Overlay)<{ animation?: 'slide' | 'grow' }>`
  background: none;
  transition: all 0.6s ${theme.ease.out};

  &.enter {
    transform: translateY(100%);
  }

  &.enter-active {
    transform: none;
  }

  &.exit {
    transform: none;
  }

  &.exit-active {
    transition-timing-function: ${theme.ease.in};
    transform: translateY(100%);
  }

  ${props =>
    props.animation === 'grow'
      ? css`
          &.enter {
            transform: scale(0.92);
            opacity: 0;
          }

          &.enter-active {
            transform: none;
            opacity: 1;
          }

          &.exit {
            transform: none;
            opacity: 1;
          }

          &.exit-active {
            transition-timing-function: ${theme.ease.in};
            transition-duration: 0.3s;
            transform: scale(0.92);
            opacity: 0;
          }
        `
      : css`
          opacity: 1 !important;
        `}
`

export const ModalBody = styled.div<{ fullscreen?: boolean }>`
  background-color: white;
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.2);
  margin: 0 2rem;
  max-height: 100%;
  width: 100%;
  max-width: 50rem;
  overflow-y: auto;
  z-index: 9500;

  ${props =>
    props.fullscreen &&
    css`
      width: 100%;
      max-width: 100%;
      margin: 0;
      height: 100%;

      ${CloseButton} {
        position: absolute;
        top: 0;
        right: 0;
      }
    `}
`

export const CloseIcon = styled(IconCloseBase)`
  font-size: 3rem;
  transition: transform 0.2s ${theme.ease.inOut};

  &:hover {
    transform: rotateZ(90deg);
  }
`

export const SmallModalBody = styled(ModalBody)`
  position: relative;
  max-width: 50rem;
  padding: 4rem 8rem;
  color: black;

  ${CloseButton} {
    position: absolute;
    top: 0;
    right: 0;
  }
`

export const CondensedModalBody = styled(SmallModalBody)`
  max-width: 48rem;

  ${CloseButton} {
    position: absolute;
    margin: 1rem;
  }

  ${CloseIcon} {
    font-size: 2.4rem;
  }
`

export const ConfirmModalBody = styled(CondensedModalBody)`
  max-width: 33rem;
  padding: 4rem 2.4rem 2.4rem;

  ${Button} {
    ${label.normal}
    flex-grow: 1;
    padding: 1.7rem 2.8rem;
    min-width: max-content;
    white-space: nowrap;
  }
`

export const Title = styled.h2<{ smallTitle?: boolean }>`
  ${heading.h4}
  margin-bottom: 2.2rem;
  text-align: center;
  text-transform: none;

  ${CondensedModalBody} & {
    ${heading.h3}
    text-transform: none;
  }

  ${ConfirmModalBody} & {
    margin-top: 0;
    font-size: 2rem;
  }

  ${props =>
    props.smallTitle &&
    css`
      font-size: 1.6rem !important;
      font-weight: 500;
    `}

  ${CondensedModalBody}:not(${ConfirmModalBody}) & {
    ${props =>
      props.smallTitle &&
      css`
        margin: -1.6rem 0 -2.4rem;
      `}
  }
`

export const Content = styled.div<{ centered?: boolean; hasPadding?: boolean }>`
  margin: 4rem -8rem -4rem;
  padding: 1rem 8rem 4rem;
  background-color: ${theme.color.neutral[25]};

  ${props =>
    props.centered &&
    css`
      text-align: center;
    `}

  ${props =>
    props.hasPadding &&
    css`
      padding-top: 4rem;
    `}

  ${CondensedModalBody} & {
    padding: 0 2.4rem 4rem;
    background-color: transparent;
  }

  ${ConfirmModalBody} & {
    margin: 0 -2.4rem;
    padding: 0 2.4rem;
    color: ${theme.color.neutral[500]};
    text-align: center;
    background-color: transparent;
  }

  ${RadioList} {
    max-width: unset;
  }
`

export const Buttons = styled.div<{
  hasButtonSpacing?: boolean
  hasButtonsInRows?: boolean
}>`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 4rem -8rem -4rem;
  padding: 1rem 8rem 4rem;
  background-color: ${theme.color.neutral[25]};

  ${props =>
    props.hasButtonSpacing &&
    css`
      > *:not(:first-child) {
        ${props.hasButtonsInRows
          ? css`
              margin-top: 1.5rem;
            `
          : css`
              margin-left: 1.5rem;
            `}
      }
    `}

  ${props =>
    props.hasButtonsInRows &&
    css`
      flex-direction: column;
    `}

  ${CondensedModalBody} & {
    padding: 0rem 2.4rem 2.4rem;
    background-color: transparent;
  }

  ${ConfirmModalBody} & {
    flex-wrap: wrap;
    margin: 3.2rem -0.2rem -0.2rem;
    padding: 0;

    > * {
      margin: 0.2rem;
    }
  }
`
