import type { FC, PropsWithChildren } from 'react'
import { createContext, useState, useContext } from 'react'

import type { GetGympassUserDataQuery } from '~/@types/schemas'
import { Modal } from '~/components/modal'
import { Portal } from '~/components/portal'

import { ForgotPasswordForm } from './forgot-password'
import { LoginForm } from './login-form'
import { SignupForm } from './signup-form'
import type { AuthState } from './types'

interface State {
  login: () => void
  signup: () => void
}

type Props = PropsWithChildren<{
  readonly gympassData?: GetGympassUserDataQuery
  readonly defaultForm?: AuthState
}>

const AuthContext = createContext<State>({
  login: () => ({}),
  signup: () => ({}),
})

export const AuthContextProvider: FC<Props> = ({
  children,
  gympassData,
  defaultForm,
}) => {
  const [isOpen, setOpen] = useState(!!gympassData)
  const [authState, setAuthState] = useState<AuthState>(defaultForm ?? 'login')

  const login = () => {
    setAuthState('login')
    setOpen(true)
  }

  const signup = () => {
    setAuthState('signup')
    setOpen(true)
  }

  return (
    <>
      <Portal>
        <Modal fullscreen isOpen={isOpen} onClose={() => setOpen(false)}>
          {authState === 'login' && (
            <LoginForm
              gympassData={gympassData}
              onSignup={() => setAuthState('signup')}
              onForgotPassword={() => setAuthState('forgot-password')}
            />
          )}
          {authState === 'signup' && (
            <SignupForm
              gympassData={gympassData}
              onLogin={() => setAuthState('login')}
            />
          )}
          {authState === 'forgot-password' && (
            <ForgotPasswordForm
              onLogin={() => setAuthState('login')}
              onClose={() => setOpen(false)}
            />
          )}
        </Modal>
      </Portal>
      <AuthContext.Provider
        value={{
          login,
          signup,
        }}
      >
        {children}
      </AuthContext.Provider>
    </>
  )
}

export const useAuthContext = () => {
  return useContext(AuthContext)
}
