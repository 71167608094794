import styled, { createGlobalStyle, css, keyframes } from 'styled-components'

export const styleReset = css`
  appearance: none;
  padding: 0;
  margin: 0;
  border: none;
  font: inherit;
  text-align: inherit;
  color: inherit;
  background: none;
`

export const Overlay = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const animation = keyframes`
  from {
    background-position: 0 50%;
  }
  to {
    background-position: -170% 50%;
  }
`

export const loadingBackground = css`
  animation: 2s ${animation} infinite;
  background: #f6f7f8
    linear-gradient(to right, #f7f7f7 33%, #eee 50%, #f7f7f7 83%) center
    center/300% 100%;
`

export const LoadingWrapper = styled.span<{
  inverted?: boolean
  block?: boolean
}>`
  display: inline-block;
  ${loadingBackground}

  &, * {
    color: transparent !important;
  }
`

export const LoadingWrapperBlock = styled(LoadingWrapper)<{ wide?: boolean }>`
  display: block;
  width: ${props => (props.wide ? 'auto' : 'max-content')};
  ${props =>
    props.block &&
    css`
      display: block;
    `}

  ${props =>
    props.inverted &&
    css`
      background: #f6f7f8
        linear-gradient(to right, #111 33%, #222 50%, #111 83%) center
        center/300% 100%;
    `}

  > * {
    visibility: hidden;
  }
`

export const CenterWrapper = styled.div`
  ${Overlay}
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const DarkPageStyle = createGlobalStyle`
  body {
    color: white;
    background: black;
  }
`
