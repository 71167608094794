import firebase from 'firebase'
import type { FC, PropsWithChildren } from 'react'
import { useState, useEffect, createContext, useContext } from 'react'

import type { AppContextType } from '~/@types/models'
import { config } from '~/utils/config'

// const noop = () => {}

const AppContext = createContext<AppContextType | null>(null)

export const AppContextProvider: FC<
  PropsWithChildren<{
    readonly value: Omit<AppContextType, 'remoteConfig'>
  }>
> = ({ children, value }) => {
  const [remoteConfig, setRemoteConfig] = useState<BarrysRemoteConfig>()

  const fetchRemoteConfig = async () => {
    if (!firebase.apps.length) {
      firebase.initializeApp(config.firebase)
    }

    await firebase.auth().signInAnonymously()

    const defaultRemoteConfig = firebase.remoteConfig()

    defaultRemoteConfig.settings = {
      minimumFetchIntervalMillis: 60 * 60 * 1000, // 1 hour
      fetchTimeoutMillis: 60000,
    }

    await defaultRemoteConfig.fetchAndActivate()

    setRemoteConfig({
      vodWorkoutEnabled: defaultRemoteConfig.getBoolean('vod_workout_enabled'),
    })
  }

  useEffect(() => {
    void fetchRemoteConfig()
  }, [])

  return (
    <AppContext.Provider value={{ ...value, remoteConfig }}>
      {children}
    </AppContext.Provider>
  )
}

export const useAppContext = () => {
  const context = useContext(AppContext)

  return context
}
