import styled from 'styled-components'

export const Label = styled.span`
  display: inline-block;
  vertical-align: middle;

  input {
    position: absolute;
    width: 0;
    height: 0;
    clip-path: polygon(0 0, 0 0, 0 0);
  }
`

export const UI = styled.span`
  display: block;
  position: relative;
  width: 2.4rem;
  height: 2.4rem;
  border: 1px solid currentColor;
  border-radius: 50%;

  input:checked + & {
    background: currentColor;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 1rem;
      height: 0.4rem;
      margin-top: -0.04em;
      border-bottom: 2px solid white;
      border-left: 2px solid white;
      mix-blend-mode: difference;
      transform: translate(-50%, -50%) rotateZ(-45deg);
      box-sizing: content-box;
    }
  }
`
