import styled from 'styled-components'

export const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 1rem;
  width: 100%;

  button {
    &:first-child {
      svg {
        font-size: 2rem;
      }
    }
  }
`
