import styled from 'styled-components'

import { styleReset } from '~/styles/misc'
import { mq } from '~/styles/mq'
import { theme } from '~/styles/theme'
import { label } from '~/styles/typography'

export const Container = styled.div`
  position: relative;

  ${mq.smallOnly} {
    margin-left: auto;
  }
`

export const UserButton = styled.button`
  ${styleReset};
  display: flex;
  align-items: center;
  margin-top: 0.4em;
  cursor: pointer;

  img,
  svg {
    width: 3.2rem;
    height: 3.2rem;
    margin: 0 1.2rem;
  }
`

export const Avatar = styled.img`
  vertical-align: middle;
  border-radius: 50%;
  color: transparent;
`

export const Backdrop = styled.div`
  ${styleReset};
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
`

export const Menu = styled.ul`
  display: block;
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 1.8rem;
  padding: 2rem 0;
  white-space: nowrap;
  box-shadow: 0 6px 36px rgba(0, 0, 0, 0.08);
  background: ${theme.color.background.light};
  z-index: 15000;

  li {
    display: block;

    a,
    button {
      ${styleReset};
      ${label.large};
      display: block;
      width: 100%;
      padding: 1rem 3.2rem 1rem 3.2rem;
      line-height: 1;
      text-decoration: none;
      cursor: pointer;
      color: ${theme.color.text.base};
      transition: color 0.3s;
      border-radius: 5px;

      &:hover,
      &:focus {
        color: ${theme.color.accent.primary};
      }
    }
  }
`

export const Email = styled.span<{ inverted?: boolean }>`
  display: block;
  font-size: 1.2rem;
  color: ${props =>
    props.inverted ? theme.color.neutral[400] : theme.color.neutral[500]};
  font-weight: normal;
`
