import styled from 'styled-components'

export const Label = styled.label`
  display: inline-block;
  vertical-align: middle;

  input {
    position: absolute;
    width: 0;
    height: 0;
    clip-path: polygon(0 0, 0 0, 0 0);
  }
`

export const UI = styled.span`
  display: block;
  position: relative;
  width: 1.5em;
  height: 1.5em;
  border: 2px solid currentColor;

  input:checked + & {
    background: currentColor;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 0.6em;
      height: 0.2em;
      margin-top: -0.04em;
      border-bottom: 2px solid white;
      border-left: 2px solid white;
      mix-blend-mode: difference;
      transform: translate(-50%, -50%) rotateZ(-45deg);
      box-sizing: content-box;
    }
  }

  label:hover &,
  label:focus &,
  input:hover + & {
    background: rgba(0, 0, 0, 0.8);
  }

  label:hover input:not(:checked):hover + &,
  label:focus input:not(:checked):hover + &,
  input:not(:checked):hover + & {
    background: rgba(0, 0, 0, 0.1);
  }
`
