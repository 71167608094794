import { gql } from '@apollo/client'

const instructor = gql`
  fragment InstructorFields on Instructor {
    id
    firstName
    lastName
    pictureUrl
    pictureUrlHq
    hasVOD
    bio
    region {
      id
      name
      country {
        code
        name
      }
    }
    lessonsCount
  }
`

const user = gql`
  fragment UserFields on User {
    id
    email
    username
    firstName
    lastName
    pictureUrl
    hasVODAccess
    isInRestrictedArea
    privacy
    loyaltyTier
    gympassUID
    friendship {
      id
      status
    }
    instructor {
      id
    }
  }
`

const membership = gql`
  fragment MembershipFields on Membership {
    id
    membershipName
    renewalRate
    renewalCurrency
    startDate
    endDate
    purchaseDate
    cancellationDate
    isCancellable
    paymentInterval
    usageIntervalLimit
  }
`

export const Fragments = {
  session: gql`
    fragment SessionFields on SessionObject {
      accessToken
      refreshToken
    }
  `,
  liveClassUser: gql`
    fragment LiveSessionUserFields on User {
      id
      firstName
      lastName
      username
      pictureUrl
    }
  `,
  user,
  instructor,
  room: gql`
    fragment LiveSessionRoomFields on LiveSessionRoom {
      id
      name
      roomIndex
      roomID
      hiddenParticipantIDs
    }
  `,
  classSession: gql`
    fragment ClassSession on ClassSession {
      id
      name
      description
      startDateTime
      endDateTime
      openingDateTime
      spotsCount
      duration
      participantsCount
      hasReservation
      userRated
      classroom {
        id
        name
      }
      region {
        id
        name
      }
      location {
        id
        name
      }
      instructors {
        ...InstructorFields
      }
      participants {
        ...UserFields
      }
      activeReservation {
        reservationType
      }
      rooms {
        name
        roomIndex
      }
    }
    ${instructor}
    ${user}
  `,
  library: gql`
    fragment LibraryItems on Library {
      id
      title
      description
      duration {
        name
        type
      }
      imageURL
      parentCategory {
        name
        type
      }
      equipment: equipment_v2 {
        name
        type
      }
      labels {
        name
        type
      }
      instructor {
        firstName
        lastName
        pictureUrl
      }
      additionalInstructor {
        firstName
        lastName
        pictureUrl
      }
      viewed
      bodyweight
      userRated
    }
  `,
  membership,
  friendship: gql`
    fragment FriendshipFields on Friendship {
      id
      status
      requester {
        id
      }
      friend {
        id
        firstName
        lastName
        username
        pictureUrl
        friendship {
          id
          status
          requester {
            id
          }
        }
      }
    }
  `,
}
