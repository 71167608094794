import { FC } from 'react'

import { Container } from '~/components/container'
import { strings } from '~/lang/en'

import { Footer as StyledFooter, Nav } from './styled'

export const Footer: FC<{
  inverted?: boolean
  className?: string
  fluid?: boolean
}> = ({ inverted, className = '', fluid }) => (
  <StyledFooter fluid={fluid} className={className} inverted={inverted}>
    <Container>
      <Nav>
        {strings.app.footer.copyright}
        <ul>
          <li>
            <a
              href="http://barrys.com/barrys-x-faq"
              target="_blank"
              rel="noopener noreferrer"
            >
              FAQ
            </a>
          </li>
          <li>
            <a
              href="https://www.barrys.com/terms-of-service/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {strings.app.footer.terms}
            </a>
          </li>
          <li>
            <a
              href="https://www.barrys.com/privacy-policy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {strings.app.footer.privacy}
            </a>
          </li>
        </ul>
      </Nav>
    </Container>
  </StyledFooter>
)
