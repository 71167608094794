import { FC, useEffect } from 'react'

import { useAppContext } from '~/context/app-context'

import { ItemContainer } from './styled'

export const NotificationItem: FC<NotificationType> = ({
  id,
  text,
  ...rest
}) => {
  const { removeNotification } = useAppContext()

  useEffect(() => {
    const timeout = setTimeout(() => {
      removeNotification(id)
    }, 4000)

    return () => clearInterval(timeout)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <ItemContainer {...rest}>{text}</ItemContainer>
}
