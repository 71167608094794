import * as Sentry from '@sentry/nextjs'

import {
  GympassEventEnum,
  useReportGympassEventMutation,
} from '~/@types/schemas'
import { useAppContext } from '~/context/app-context'

export const useGympassReporting = () => {
  const { user } = useAppContext()

  const [reportGympassEvent, { loading }] = useReportGympassEventMutation({
    onError: err =>
      Sentry.captureMessage('Store Device Info failed', {
        extra: { error: err },
        level: Sentry.Severity.Error,
        user: { userID: user?.id || '' },
      }),
  })

  const reportEvent = (
    eventType: GympassEventEnum,
    additionalCondition?: boolean,
    accessToken?: string
  ) => {
    if (user?.gympassUID || additionalCondition) {
      reportGympassEvent({
        variables: { eventType },
        ...(accessToken
          ? {
              context: {
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
              },
            }
          : null),
      })
    }
  }

  return {
    reportEvent,
    loading,
  }
}
