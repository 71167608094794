import { createGlobalStyle } from 'styled-components'

import { theme } from '~/styles/theme'
import { font } from '~/styles/typography'

export const GlobalStyle = createGlobalStyle`
  :root {
    --sidebar-width: 25%;
    --sidebar-max-width: 40rem;
  }

  html,
  body {
    padding: 0;
    margin: 0;
    height: 100%;
  }

  html {
    font-size: 62.5%;
    font-family: ${font.base};
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smooth: antialiased; /* stylelint-disable-line property-no-unknown */
  }

  body {
    font-size: 1.4rem;
    color: ${theme.color.text.base};
    background-color: ${theme.color.background.light};
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  a {
    color: inherit;
  }

  ul,
  ol {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }

  input {
    font: inherit;
    color: inherit;
  }

  #__next {
    display: flex;
    flex-direction: column;
    min-height: 100%;

    > * {
      flex-shrink: 0;
    }
  }

  #nprogress {
    position: relative;
    z-index: 20000;

    .bar { background: ${theme.color.accent.primary} !important; }
    .peg { box-shadow: 0 0 10px ${theme.color.accent.primary}, 0 0 5px ${theme.color.accent.primary} !important; }

    .spinner-icon {
      border-top-color:  ${theme.color.accent.primary} !important;
      border-left-color: ${theme.color.accent.primary} !important;
    }
  }

  #musickit-dialog-scrim, #musickit-dialog {
    display: none !important;
  }

  body.locked {
    overflow: hidden;
  }
`
