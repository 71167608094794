// eslint-disable-next-line no-shadow
export enum ScreenSize {
  SM = 375,
  MD = 768,
  LG = 1300,
}

export const mq = {
  smallOnly: `@media (max-width: ${ScreenSize.MD / 16}em)`,
  mediumOnly: `@media (min-width: ${ScreenSize.MD / 16}em) and (max-width: ${
    ScreenSize.LG / 16
  }em)`,

  medium: `@media (min-width: ${ScreenSize.MD / 16}em)`,
  large: `@media (min-width: ${ScreenSize.LG / 16}em)`,
  short: `@media (max-height: 40em)`,
}
