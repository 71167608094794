import { HTMLAttributes, forwardRef } from 'react'

import { Loading } from './styled'

import { Button, ButtonProps } from '.'

type Props = { isLoading?: boolean; disabled?: boolean } & ButtonProps &
  HTMLAttributes<HTMLButtonElement>

export const ButtonWithLoading = forwardRef<HTMLButtonElement, Props>(
  ({ children, isLoading, disabled, ...props }, ref) => (
    <Button ref={ref} disabled={disabled || isLoading} {...props}>
      {/* eslint-disable-next-line react/forbid-dom-props */}
      <span style={isLoading ? { color: 'transparent' } : null}>
        {children}
      </span>

      {isLoading && (
        <Loading viewBox="0 0 80 80">
          <circle
            cx="40"
            cy="40"
            r="40"
            fill="none"
            stroke="currentColor"
            strokeWidth="8"
            strokeDasharray="180"
          />
        </Loading>
      )}
    </Button>
  )
)
