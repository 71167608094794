// eslint-disable-next-line no-shadow
import atob from 'atob'
import { DateTime, Interval, LocaleOptions } from 'luxon'
import { Participant } from 'twilio-video'

import { ClassWithInstructors, FirebaseParticipant } from '~/@types/models'
import { LibraryItemCategoryEnum, User } from '~/@types/schemas'

export const titleToSlug = (title: string) =>
  title
    .toLowerCase()
    .replace('&', 'and')
    .replace(/\s+/gu, '-')
    .replace(/[^a-z0-9-]+/gu, '')

export const slugToCategoryEnum = (slug: string) => {
  switch (slug) {
    case 'strength':
      return LibraryItemCategoryEnum.Strength
    case 'strength-and-cardio':
      return LibraryItemCategoryEnum.StrengthCardio
    case 'cardio':
      return LibraryItemCategoryEnum.Cardio
    default:
      return LibraryItemCategoryEnum.Strength
  }
}

export const parseJwt = (
  token: string
): { sessionId: string; userID: string } => {
  if (!token) {
    return { sessionId: '', userID: '' }
  }

  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/gu, '+').replace(/_/gu, '/')

  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(c => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  )

  return JSON.parse(jsonPayload)
}

export const relativeDateTimeFormat = (date: string, hideZone?: boolean) => {
  const options = {
    locale: 'en-us',
  }

  const dateObj = DateTime.fromISO(date)

  const relativeDate =
    Interval.fromDateTimes(dateObj, DateTime.utc()).length('days') < 2 ||
    dateObj.hasSame(DateTime.utc(), 'day')
      ? dateObj.toRelativeCalendar(options) // displays a relative date, such as "today"
      : dateObj.toLocaleString(options as Intl.DateTimeFormatOptions) // displays a usual US format, such as "10/11/2020"

  return `${relativeDate}, ${dateObj.toLocaleString({
    ...(DateTime.TIME_SIMPLE as LocaleOptions),
    ...options,
  })} ${!hideZone ? dateObj.offsetNameShort : ''}`
}

export const getDateDistanceFromNow = (
  date: string,
  units: Parameters<Interval['length']>[0],
  todayDate?: DateTime
) => {
  const dateObj = DateTime.fromISO(date)
  const today = todayDate || DateTime.utc()
  const interval = Interval.fromDateTimes(today, dateObj)

  return interval.isValid
    ? interval.length(units)
    : -Interval.fromDateTimes(dateObj, today).length(units)
}

export const pluralizeString = (word: string, count: number) => {
  const result = `${count} ${word}`

  if (count !== 1) {
    return result + 's'
  }

  return result
}

export const getIdentity = (p: Participant | FirebaseParticipant | User) => {
  if (!p) {
    return null
  }

  return 'identity' in p ? p.identity : p.id
}

export const formatTime = (timeSecs: number) => {
  const mins = Math.floor(timeSecs / 60)
  const secs = Math.floor(timeSecs % 60)

  return `${mins < 10 ? `0${mins}` : mins}:${secs < 10 ? `0${secs}` : secs}`
}

export const formatDate = (date: string) => {
  return DateTime.fromISO(date).toFormat('MMM d, yyyy')
}

export const shortenInstructorsName = ({
  firstName,
  lastName,
}: {
  firstName?: string
  lastName?: string
}) => {
  if (firstName && lastName) {
    return `${firstName} ${lastName[0]}.`
  }

  return firstName
}

const getInstructors = (classWithInstructors: ClassWithInstructors) =>
  [
    classWithInstructors.instructor,
    classWithInstructors.additionalInstructor,
  ].filter(i => Boolean(i))

export const instructorAvatars = (
  classWithInstructors: ClassWithInstructors
) => {
  return getInstructors(classWithInstructors).map(i => i.pictureUrl)
}

export const instructorNames = (classWithInstructors: ClassWithInstructors) => {
  return getInstructors(classWithInstructors)
    .map(shortenInstructorsName)
    .join(', ')
}

export const stripInputDeviceIDFromLabel = (
  device: MediaDeviceInfo,
  index: number
) =>
  (
    device.label ||
    `${device.kind === 'videoinput' ? 'Video' : 'Audio'} input ${index + 1}`
  ).replace(
    // eslint-disable-next-line prefer-named-capture-group
    /\(([a-z0-9]{4}):([a-z0-9]{4})\)$/gu,
    ''
  )

export const enumToReadable = (enumValue: string) => enumValue.replace('_', ' ')

// eslint-disable-next-line @typescript-eslint/promise-function-async
export const delay = (valueMs: number) =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve(true)
    }, valueMs)
  })

export const isLastIndex = (array: unknown[], index: number) => {
  return index === array.length - 1
}

export const isEvenIndex = (index: number) => {
  return index % 2 === 0
}
