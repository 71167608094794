import styled from 'styled-components'

import { mq } from '~/styles/mq'
import { theme } from '~/styles/theme'
import { paragraph } from '~/styles/typography'

export const RadioList = styled.ul`
  max-width: 37.5rem;
`

export const Item = styled.li`
  position: relative;

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-bottom: 1px solid #e6e6e935;
    mix-blend-mode: difference;
  }

  label {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s;
    margin: 0 -2rem;
    padding: 2rem;
    overflow: hidden;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 100%;
      transition: all 0.4s;
      transition-timing-function: ${theme.ease.inOut};
      transform: skew(-45deg);
      transform-origin: 0 0;
      background: transparent;
    }

    &:hover,
    &:focus {
      &::before {
        width: 100%;
        transform: none;
        background-color: #e6e6e928;
      }
    }

    h3 {
      ${paragraph.normal}
      margin: 0 0 0.2rem;
    }

    p {
      ${paragraph.small}
      margin: 0;
      opacity: 0.8;
    }
  }
`

export const ItemContent = styled.span`
  display: block;
  flex-grow: 1;
  margin: 0 0 0 1.6rem;
  order: 1;

  ${mq.medium} {
    order: 0;
    margin: 0 2rem 0 0;
  }
`
