import { FC } from 'react'

import { Svg } from '~/components/svg'

export const IconExternal: FC = props => (
  <Svg {...props} viewBox="0 0 24 24">
    <g stroke="currentColor" fill="none" strokeWidth="2" strokeLinecap="square">
      <path d="M17 14.6667V16.6667C17 17.0203 16.8595 17.3594 16.6095 17.6095C16.3594 17.8595 16.0203 18 15.6667 18H8.33333C7.97971 18 7.64057 17.8595 7.39052 17.6095C7.14048 17.3594 7 17.0203 7 16.6667V9.33333C7 8.97971 7.14048 8.64057 7.39052 8.39052C7.64057 8.14048 7.97971 8 8.33333 8H10.3333" />
      <path d="M14.9997 6H18.9997V10" />
      <path d="M12 13L18 7" />
    </g>
  </Svg>
)
