import styled, { keyframes, css } from 'styled-components'

export const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 3rem;
  pointer-events: none;
  text-align: center;
  z-index: 10000;

  > div {
    display: inline-block;
  }
`

const animateIn = keyframes`
  from {
    transform: translateY(50%);
    opacity: 0;
  }

  10% {
    transform: none;
    opacity: 1;
  }

  90% {
    transform: none;
    opacity: 1;
  }

  to {
    transform: translateX(-10%);
    opacity: 0;
  }
`

export const ItemContainer = styled.div<{
  type?: NotificationType['type']
}>`
  box-shadow: 0 0 28px rgba(0, 0, 0, 0.1), 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  color: white;
  font-size: 1.4rem;
  padding: 1em 1.4em;
  margin-top: 1rem;
  pointer-events: auto;
  font-weight: bold;
  animation: 3.5s ${animateIn} forwards;
  backdrop-filter: blur(24px);
  background-color: rgba(50, 50, 50, 0.6);

  ${props =>
    props.type === 'error' &&
    css`
      color: white;
      background: rgba(255, 0, 0, 0.7);
    `}

  ${props =>
    props.type === 'success' &&
    css`
      color: white;
      background: rgba(50, 200, 0, 0.7);
    `}
`
