import { strings } from '~/lang/en'

import { useAuthContext } from './auth-context'
import { Intro, ButtonContainer, LoginButton } from './styled'

export const Actions = () => {
  const { login } = useAuthContext()

  return (
    <>
      <Intro>{strings.login.welcome}</Intro>
      <ButtonContainer>
        <LoginButton primary onClick={login}>
          Log in
        </LoginButton>
      </ButtonContainer>
    </>
  )
}
