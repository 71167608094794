import styled from 'styled-components'

import { Container as ContainerBase } from '~/components/container'
import { heading, paragraph } from '~/styles/typography'

export const Container = styled(ContainerBase)`
  margin: auto;
  text-align: center;
`

export const Title = styled.h1`
  ${heading.h2}
  text-transform: none;
`

export const Details = styled.p`
  ${paragraph.large}
`

export const Message = styled.p`
  ${paragraph.small}
  margin-left: auto;
  margin-right: auto;
  max-width: 30em;
`
