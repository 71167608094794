import { FC, SVGAttributes } from 'react'

import { Svg } from '~/components/svg'

export const IconClose: FC<SVGAttributes<SVGSVGElement>> = props => (
  <Svg {...props} viewBox="0 0 24 24">
    <path d="M18 6L6 18" stroke="currentColor" strokeWidth="2" />
    <path d="M6 6L18 18" stroke="currentColor" strokeWidth="2" />
  </Svg>
)
