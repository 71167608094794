import { LibraryItemCategoryEnum } from '~/@types/schemas'

export const strings = {
  app: {
    incomplete_profile: {
      title: (
        <>
          Your profile <br /> is incomplete
        </>
      ),
      description: (
        <>
          It seems that you have not finished an onboarding for Barry&rsquo;s X.
          Please log into the app, finish the onboarding and then reload this
          page.
        </>
      ),
      download: (
        <>
          If you do not have Barry&rsquo;s X app installed on your mobile
          device, you can download it here:
        </>
      ),
    },
    footer: {
      copyright: `© Barry’s ${new Date().getFullYear()}`,
      terms: 'Terms & Conditions',
      privacy: 'Privacy Policy',
    },
  },
  login: {
    welcome: (
      <>
        The Best (Digital) Workout in the World.
        <br /> Connect with the community and join the Fit Fam.
      </>
    ),
    sign_up_button: 'Register',
    mobile: 'To continue, please download the Barry’s X app to your phone.',
    form_title: 'Log in',
    forgot_password: {
      cta: 'Forgot password',
      confirmation:
        "Instructions to reset your password have been sent to the email address attached to your Barry's X account. Check your inbox.",
      form_title:
        "We got you. Type in the email address attached to your Barry's X account and we'll send you a link to reset.",
      back: 'Back to login',
    },
    login_button: 'Log in',
  },
  dashboard: {
    instructor_toggles: {
      instructing: 'Instructing classes',
      participating: 'Participating classes',
    },
    empty: {
      title: 'No classes saved',
      description: "Download Barry's X app:",
    },
    load_more_button: 'More Upcoming Classes',
    next_class_title: 'Your next class starts in',
    title: (name: string) => `Welcome Back, ${name}!`,
    completed_count: 'Completed X Workouts',
    completed_time: 'Time dedicated to your fitness',
    category_labels: {
      [LibraryItemCategoryEnum.Cardio]: 'Cardio',
      [LibraryItemCategoryEnum.Strength]: 'Strength',
      [LibraryItemCategoryEnum.StrengthCardio]: 'Strength & Cardio',
      [LibraryItemCategoryEnum.Stretch]: 'Stretch',
    },
  },
  vod: {
    category: {
      not_found: {
        title: 'Ouch!',
        description: <>We don&rsquo;t have this category, sorry.</>,
      },
      instructor_not_found: {
        title: 'Ouch!',
        description: <>We don&rsquo;t have this instructor, sorry.</>,
      },
      total_workouts: (value: number) => `${value} Workouts`,
      load_more: 'Load more items',
    },
    filters: {
      not_found: {
        title: 'Ouch!',
        description:
          'Did you go crazy with the filters or did the dog eat your bands? Don’t frown and go get some new ones!',
      },
    },
    equipment: 'Your Equipment',
    disabled:
      'Please Hold: On-Demand content will be accessible when your membership activates on August 10th.',
    no_access: {
      description: (
        <>
          You don&rsquo;t have access to this content. To access this class,
          please buy VOD membership in the Barry&rsquo;s X app first.
        </>
      ),
      download: (
        <>If you don&rsquo;t have the app yet, you can download it here:</>
      ),
    },
    start_class: 'Start Class',
    instructor_detail: {
      no_results: {
        title: 'Ouch!',
        description:
          'Did you go crazy with the filters or did the dog eat your bands? Don’t frown and go get some new ones!',
      },
    },
  },
  liveclass: {
    common: {
      error: 'Something went wrong',
      class_already_finished: 'The class has already finished',
      joining_class: 'Joining class…',
      disconnected: 'You have been disconnected',
      audio_modal: {
        title: 'Can’t hear anyone? Let’s fix that.',
        description:
          'We have detected that your internet browser is blocking audio coming from other participants and the instructor. To turn audio on, please click the button below.',
        cta: 'Fix audio',
      },
      sidebar: {
        chat: {
          anonymous_sender_name: 'Private User',
          input_placeholder: 'Type your message here…',
          character_countdown: (max: number, value: number) =>
            `Maximum length ${max} characters, ${max - value} left`,
        },
        people: {
          no_friends: 'None of your friends are in this class.',
          hidden_users: 'Private users',
        },
      },
    },
    participant: {
      banned: {
        title: (
          <>
            You&rsquo;ve been banned from the Live Class by&nbsp;Barry&rsquo;s
          </>
        ),
        description:
          'If you think that could have been a mistake, please email us at support@barrys.com',
      },
      leave_class: {
        modal_title: <>Are you sure you want to&nbsp;leave this live class?</>,
        modal_description:
          'You are about to leave this live class. If you leave now, you will be able to reconnect anytime before the end of the class.',
        confirmation: 'You left the class',
        rejoin_button: 'Rejoin',
      },
      no_instructor_placeholder: 'Your instructor will be right back',
      rating_modal: {
        title: 'Class finished, great job!',
        confirmation: 'Your feedback has been sent, thank you.',
        instructor_title: 'How was your instructor?',
        instructor_validation_error: 'Please rate the instructor',
        experience_title: 'Please rate your experience',
        experience_validation_error: 'Please rate your experience',
        emoji_select_title: 'Please select',
        note_title: 'Anything you’d like to add?',
        send_button: 'Send',
        hide_countdown: (value: number) =>
          `This window will close in ${value} seconds.`,
      },
      after_finish: 'The class has finished.',
      controls: {
        disabled_audio: 'Your audio was disabled by the instructor',
        disabled_audio_in_session:
          'Audio input is disabled for this part of the live class',
        audio_on: 'Turn on sound',
        audio_off: 'Turn off sound',
        no_microphone: 'No microphone found',
        disabled_video: 'Your video was disabled by the instructor',
        video_on: 'Turn on video',
        video_off: 'Turn off video',
        no_camera: 'No camera found',
        video_instructor_only: 'Instructor only',
        video_preference: {
          all: 'Present to everyone',
          instructor: 'Present only to the instructor',
          instructor_and_friends: 'Present to the instructor and friends',
        },
        quick_reactions: [
          "💪 Let's gooo!",
          '🖤 Love this workout!',
          '🔥 Feel the burn!',
        ],
        quick_reactions_title: 'Add a quick reaction',
      },
      starting_soon: 'Class is starting soon',
    },
    instructor: {
      sidebar: {
        room_info: (value: string) => (
          <>
            Currently in <span>{value}</span>
          </>
        ),
        elapsed_time_title: 'Elapsed time',
        start_paylist_button: 'Start Playlist',
        start_class_button: 'Start Class',
      },
      finish_class: {
        cta: 'Finish Class',
        confirmation: <>You have finished the&nbsp;class</>,
      },
      moderator_name: {
        title: 'Who is the moderator today?',
      },
      room_selection: {
        title: 'Please select a room',
        description:
          'This class session has multiple rooms. Please select a room to which you want to connect from this device.',
      },
      ban_confirmation: {
        title: (value = 'this user') =>
          `Are you sure you want to ban ${value} from this class`,
        description: (value = 'This user') => (
          <>
            {value} will not be able to connect to this live class. This action
            can&rsquo;t be undone.
          </>
        ),
      },
      moderator_controls: {
        video: 'Turn off video for this user',
        audio: 'Turn off sound for this user',
        ban: 'Kick this user out of this live class',
        mute_session: 'Mute everyone in all rooms of this live class',
        unmute_session: 'Unmute everyone in all rooms of this live class',
      },
    },
  },
  memberships: {
    title: 'Membership management',
    no_memberships: <>You don&apos;t have any active BarrysX memberships.</>,
    cancel_modal: {
      title: 'Are you sure? You can not take this back. No refunds.',
      yes: 'Yes, continue cancellation',
      no: 'No, go back',
    },
    card: {
      description: (
        <>
          Valid exclusively for Barry&apos;s Live Stream At-Home classes in all
          regions. Credit expires 30 days from purchase.
        </>
      ),
      cancel_cta: 'Cancel membership',
    },
    purchase_card: {
      title: 'Active membership',
      description: 'Subscribed via Apple/Google subscription',
      faq_cta: 'FAQ',
    },
  },
  faq: [
    {
      question: 'Question numero uno',
      answer:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.",
    },
    {
      question: 'Question numero due',
      answer:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.",
    },
    {
      question: 'Question numero tres',
      answer:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.",
    },
  ],
}
