import { useRouter } from 'next/router'
import { useState } from 'react'

import {
  GetGympassUserDataQuery,
  GympassEventEnum,
  useCreateUserMutation,
} from '~/@types/schemas'
import { useAppContext } from '~/context/app-context'
import { useGympassReporting } from '~/hooks/useGympassReporting'

import { AccountForm } from './account-form'
import { PersonalForm } from './personal-form'
import { AccountValues, PersonalValues } from './types'

import { FormWrapper, Description, ButtonLink } from '../styled'


type Props = {
  onLogin: () => void
  gympassData?: GetGympassUserDataQuery
}

type Step = 'personal' | 'account'

type Values = PersonalValues & AccountValues

const defaultValues = (gympassData?: GetGympassUserDataQuery): Values => ({
  firstName: gympassData?.getGympassUserData.firstName ?? '',
  lastName: gympassData?.getGympassUserData.lastName ?? '',
  email: gympassData?.getGympassUserData.email ?? '',
  birthDate: '',
  password: '',
  isWaiverSigned: true,
  isMarketingOptIn: false,
  homeLocationId: '',
})

export const SignupForm = ({ onLogin, gympassData }: Props) => {
  const { login, addNotification } = useAppContext()
  const router = useRouter()
  const [isLoading, setLoading] = useState(false)
  const [step, setStep] = useState<Step>('personal')
  const [values, setValues] = useState<Values>(defaultValues(gympassData))
  const [createUser] = useCreateUserMutation()
  const { reportEvent } = useGympassReporting()

  const handleContinue = (newValues: PersonalValues) => {
    setValues(prev => ({
      ...prev,
      ...newValues,
    }))

    setStep('account')
  }

  const handleBack = (newValues: AccountValues) => {
    setValues(prev => ({
      ...prev,
      ...newValues,
    }))

    setStep('personal')
  }

  const handleSubmit = async (newValues: AccountValues) => {
    setLoading(true)

    try {
      const { data } = await createUser({
        variables: {
          input: {
            ...values,
            ...newValues,
          },
        },
      })

      login(data.createUser, true)

      reportEvent(
        GympassEventEnum.Signup,
        !!data.createUser.user.gympassUID,
        data.createUser.session.accessToken
      )

      await router.push('/onboarding')
    } catch (err) {
      addNotification(err.message)
    }

    setLoading(false)
  }

  return (
    <FormWrapper>
      {step === 'personal' && (
        <PersonalForm onSubmit={handleContinue} initialValues={values} />
      )}
      {step === 'account' && (
        <AccountForm
          onSubmit={handleSubmit}
          onBack={handleBack}
          initialValues={values}
          isLoading={isLoading}
        />
      )}
      <Description>
        <p>
          Already part of the Barry’s Fam?
          <br />
          <ButtonLink type="button" onClick={onLogin}>
            Log in here
          </ButtonLink>
        </p>
      </Description>
    </FormWrapper>
  )
}
