import Head from 'next/head'
import type { FC, PropsWithChildren } from 'react'

import type { StructuredData } from '~/@types/data'

type Props = PropsWithChildren<{
  readonly description?: string
  readonly keywords?: readonly string[]
  readonly title?: string
  readonly type?: string
  readonly url?: string
  readonly image?: string
  readonly structuredData?: readonly StructuredData[]
}>

export const SEO: FC<Props> = ({
  title,
  keywords = [],
  structuredData = [],
  type = 'website',
  image,
  // url,
  children,
}) => {
  const websiteSD: StructuredData = {
    '@context': 'http://schema.org',
    '@type': 'WebSite',
    // url: config.siteUrl,
    name: title,
    alternateName: title,
  }

  return (
    <Head>
      <title>{title ? `${title} | Barry's X` : "Barry's X"}</title>
      <script type="application/ld+json">
        {JSON.stringify([...structuredData, websiteSD])}
      </script>
      <meta name="keywords" content={(keywords || []).join(', ')} />
      <meta property="og:title" content={title} />
      <meta property="og:type" content={type} />
      {/* <meta property="og:url" content={url || config.siteUrl} /> */}
      <meta property="og:image" content={image} />
      {children}
    </Head>
  )
}
