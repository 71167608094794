/* eslint-disable @typescript-eslint/promise-function-async */
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'

import {
  ConnectClientMutation,
  ConnectClientMutationVariables,
  CountLibraryItemsQuery,
  CountLibraryItemsQueryVariables,
  GetClassSessionQuery,
  GetLiveSessionQuery,
  GetLiveSessionQueryVariables,
  GetUserQuery,
  GetUserQueryVariables,
  GetUserStatsQuery,
  LibraryItemCategoryEnum,
  ListInstructorsQuery,
  ListInstructorsQueryVariables,
  ListInstructorsInput,
  ListLibraryItemsInput,
  ListLibraryItemsQuery,
  ListLibraryItemsQueryVariables,
  ListVodFiltersQuery,
  ListVodFiltersQueryVariables,
  ListLibraryItemCategoriesQueryVariables,
  ListLibraryItemCategoriesQuery,
  GetLibraryItemQuery,
  GetLibraryItemQueryVariables,
  ListUserClassSessionsQuery,
  ListUserClassSessionsQueryVariables,
  GetOnDemandCollectionQuery,
  GetOnDemandCollectionQueryVariables,
  PaginationInput,
  CreateRatingForClassSessionMutation,
  CreateRatingForClassSessionMutationVariables,
  RatingLevel,
  ListClassSessionInput,
  ListClassSessionsQuery,
  ListClassSessionsQueryVariables,
  ListUserMembershipsQuery,
  ListUserMembershipsQueryVariables,
  PurchaseTypeEnum,
  ListUserPurchasesQuery,
  ListUserPurchasesQueryVariables,
  GetFileUrlInput,
  GetFileUrlQuery,
  GetFileUrlQueryVariables,
  GetClassSessionQueryVariables,
  ListWorkoutSummaryInput,
  ListWorkoutSummariesQuery,
  ListWorkoutSummariesQueryVariables,
} from '~/@types/schemas'

import { initializeApollo } from './apollo-client'
import { CONNECT_CLIENT, SEND_SESSION_RATING } from './mutations'
import {
  GET_LIVE_SESSION,
  GET_USER,
  GET_USER_STATS,
  LIST_INSTRUCTORS,
  LIST_VOD_FILTERS,
  COUNT_LIBRARY_ITEMS,
  GET_CLASS_SESSION,
  LIST_LIBRARY_ITEMS,
  LIST_LIBRARY_ITEM_CATEGORIES,
  GET_LIBRARY_ITEM,
  LIST_USER_CLASS_SESSIONS,
  GET_ON_DEMAND_COLLECTION,
  LIST_CLASS_SESSIONS,
  LIST_USER_MEMBERSHIPS,
  LIST_USER_PURCHASES,
  GET_FILE_URL,
  LIST_WORKOUT_SUMMARIES,
} from './queries'

export const api = (tokenManager: TokenManager) => {
  const apolloClient: ApolloClient<NormalizedCacheObject> = initializeApollo(
    tokenManager,
    null
  )

  const getUser = (id?: string) =>
    apolloClient
      .query<GetUserQuery, GetUserQueryVariables>({
        query: GET_USER,
        variables: { id },
        fetchPolicy: 'network-only',
      })
      .then(response => response.data.getUser)

  const getUserStats = (id: string) =>
    apolloClient
      .query<GetUserStatsQuery, GetUserQueryVariables>({
        query: GET_USER_STATS,
        variables: { id },
      })
      .then(response => response.data.getUserStats)

  const listUserClassSessions = (page = 1, size = 10) =>
    apolloClient
      .query<ListUserClassSessionsQuery, ListUserClassSessionsQueryVariables>({
        query: LIST_USER_CLASS_SESSIONS,
        variables: {
          page,
          size,
        },
      })
      .then(response => response.data.listUserClassSessions)

  const listUserMemberships = (page = 1, size = 100) =>
    apolloClient
      .query<ListUserMembershipsQuery, ListUserMembershipsQueryVariables>({
        query: LIST_USER_MEMBERSHIPS,
        variables: {
          page,
          size,
        },
      })
      .then(response => response.data.listUserMemberships)

  const listUserPurchases = (
    type: PurchaseTypeEnum = PurchaseTypeEnum.Subscription
  ) =>
    apolloClient
      .query<ListUserPurchasesQuery, ListUserPurchasesQueryVariables>({
        query: LIST_USER_PURCHASES,
        variables: {
          type,
        },
      })
      .then(response => response.data.listUserPurchases)

  const listClassSessions = (input: ListClassSessionInput) =>
    apolloClient
      .query<ListClassSessionsQuery, ListClassSessionsQueryVariables>({
        query: LIST_CLASS_SESSIONS,
        variables: { input },
      })
      .then(response => response.data.listClassSessions)

  const connectClient = (id: string, roomIndex?: number) =>
    apolloClient
      .mutate<ConnectClientMutation, ConnectClientMutationVariables>({
        mutation: CONNECT_CLIENT,
        variables: { input: { classSessionID: id, roomIndex } },
      })
      .then(response => response.data.connectClient)

  const getLiveSession = (liveSessionID: string) =>
    apolloClient
      .query<GetLiveSessionQuery, GetLiveSessionQueryVariables>({
        query: GET_LIVE_SESSION,
        variables: { id: liveSessionID },
      })
      .then(response => response.data.getLiveSession)

  const listWorkoutSummaries = (input: ListWorkoutSummaryInput) =>
    apolloClient.query<
      ListWorkoutSummariesQuery,
      ListWorkoutSummariesQueryVariables
    >({
      query: LIST_WORKOUT_SUMMARIES,
      variables: { input },
    })

  const getClassSession = (classID: string) =>
    apolloClient
      .query<GetClassSessionQuery, GetClassSessionQueryVariables>({
        query: GET_CLASS_SESSION,
        variables: { id: classID },
      })
      .then(response => response.data.getClassSession)

  const listInstructors = (input: ListInstructorsInput) =>
    apolloClient
      .query<ListInstructorsQuery, ListInstructorsQueryVariables>({
        query: LIST_INSTRUCTORS,
        variables: { input },
      })
      .then(response => response.data.listInstructors)

  const listVodFilters = (category: LibraryItemCategoryEnum) =>
    apolloClient
      .query<ListVodFiltersQuery, ListVodFiltersQueryVariables>({
        query: LIST_VOD_FILTERS,
        variables: { category },
      })
      .then(response => response.data.listVodFilters)

  const countLibraryItems = (input?: ListLibraryItemsInput) =>
    apolloClient
      .query<CountLibraryItemsQuery, CountLibraryItemsQueryVariables>({
        query: COUNT_LIBRARY_ITEMS,
        variables: { input },
      })
      .then(response => response.data.countLibraryItems)

  const listLibraryItems = (input?: ListLibraryItemsInput) =>
    apolloClient
      .query<ListLibraryItemsQuery, ListLibraryItemsQueryVariables>({
        query: LIST_LIBRARY_ITEMS,
        variables: { input },
      })
      .then(response => response.data.listLibraryItems)

  const sendSessionRating = (
    ratingQ1: RatingLevel,
    ratingQ2: RatingLevel,
    additionalComments: string,
    classSessionID: string
  ) =>
    apolloClient
      .mutate<
        CreateRatingForClassSessionMutation,
        CreateRatingForClassSessionMutationVariables
      >({
        mutation: SEND_SESSION_RATING,
        variables: { ratingQ1, ratingQ2, additionalComments, classSessionID },
      })
      .then(response => response.data.createRatingForClassSession)

  const listLibraryItemCategories = () =>
    apolloClient
      .query<
        ListLibraryItemCategoriesQuery,
        ListLibraryItemCategoriesQueryVariables
      >({
        query: LIST_LIBRARY_ITEM_CATEGORIES,
      })
      .then(response => response.data.listLibraryItemCategories)

  const getLibraryItem = (id: string) =>
    apolloClient
      .query<GetLibraryItemQuery, GetLibraryItemQueryVariables>({
        query: GET_LIBRARY_ITEM,
        variables: { id },
      })
      .then(response => response.data.getLibraryItem)

  const getOnDemandCollection = (id: string, input: PaginationInput) =>
    apolloClient
      .query<GetOnDemandCollectionQuery, GetOnDemandCollectionQueryVariables>({
        query: GET_ON_DEMAND_COLLECTION,
        variables: { id, input },
      })
      .then(response => response.data.getOnDemandCollection)

  const getFileUrl = (input: GetFileUrlInput) =>
    apolloClient
      .query<GetFileUrlQuery, GetFileUrlQueryVariables>({
        query: GET_FILE_URL,
        variables: { input },
        fetchPolicy: 'network-only',
      })
      .then(response => response.data.getFileURL_v3)

  return {
    apolloClient,
    getUser,
    listUserClassSessions,
    listClassSessions,
    listUserMemberships,
    listUserPurchases,
    getUserStats,
    connectClient,
    getLiveSession,
    listInstructors,
    listVodFilters,
    countLibraryItems,
    listLibraryItems,
    listWorkoutSummaries,
    getClassSession,
    listLibraryItemCategories,
    getLibraryItem,
    getOnDemandCollection,
    sendSessionRating,
    getFileUrl,
  }
}
