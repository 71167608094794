const color = {
  black: '#000',
  white: '#fff',
  barrysRed: '#D61638',
  neutral: {
    25: '#F8F8F9',
    50: '#F4F4F5',
    100: '#E6E6E9',
    200: '#D7D7DB',
    300: '#B9B9C0',
    400: '#9F9FA8',
    500: '#72727E',
    600: '#5C5C66',
    700: '#45454D',
    800: '#38383E',
    900: '#27272B',
    950: '#1D1D20',
    975: '#131315',
  },
  red: {
    400: '#E22840',
  },
}

export const theme = {
  color: {
    ...color,
    background: {
      dark: '#1D1F2A',
      light: color.white,
      lightGrey: '#F5F5F5',
    },
    text: {
      base: color.black,
      light: color.white,
      grey: color.neutral[500],
    },
    accent: {
      primary: color.barrysRed,
      error: color.red[400],
    },
  },
  layout: {
    spacing: {
      large: '8rem',
      medium: '4rem',
      small: '2rem',
    },
  },
  ease: {
    in: 'cubic-bezier(.55,.06,.68,.19)',
    out: 'cubic-bezier(0.22, 0.61, 0.36, 1)',
    inOut: 'cubic-bezier(.46,.03,.52,.96)',
  },
  easeValues: {
    in: [0.55, 0.06, 0.68, 0.19],
    out: [0.22, 0.61, 0.36, 1],
    inOut: [0.46, 0.03, 0.52, 0.96],
  },
}
