import * as Sentry from '@sentry/nextjs'
import type { ErrorInfo, PropsWithChildren } from 'react'
import { Component } from 'react'

import { AppError } from '~/containers/app-error'

interface State {
  readonly errorMessage: string
}

export class ErrorBoundary extends Component<PropsWithChildren, State, State> {
  // eslint-disable-next-line react/sort-comp
  state = { errorMessage: '' }

  static getDerivedStateFromError(error) {
    return { errorMessage: error?.message ?? 'unknown error' }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    Sentry.captureMessage(error.message, {
      extra: { error, errorInfo },
      level: Sentry.Severity.Error,
    })
  }

  render() {
    if (this.state.errorMessage) {
      return <AppError message={this.state.errorMessage} />
    }

    return this.props.children
  }
}
