import { FC } from 'react'

import { Svg } from '~/components/svg'

export const IconUser: FC = props => (
  <Svg {...props} viewBox="0 0 26 26">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.167 7.792a4.167 4.167 0 1 1-8.334 0 4.167 4.167 0 0 1 8.334 0Zm-7.414 6.214a9.068 9.068 0 0 1 3.26-.478 8.964 8.964 0 0 1 3.253.474c1.038.356 1.984.9 2.78 1.596l.204.23v6.02s-.637.013-1.361 0H8.11c-.716.013-1.361 0-1.361 0v-6.035l.213-.215a8.414 8.414 0 0 1 2.79-1.592Z"
      fill="#9F9FA8"
    />
  </Svg>
)
