import type { PropsWithChildren } from 'react'
import type { IStyledComponent } from 'styled-components'
import styled, { css } from 'styled-components'

import { mq } from '~/styles/mq'
import { theme } from '~/styles/theme'
import { label } from '~/styles/typography'

export type ButtonProps = PropsWithChildren<{
  inverted?: boolean
  outline?: boolean
  wide?: boolean
  primary?: boolean
  block?: boolean
  invisible?: boolean
}>

export const ButtonBase = styled.button`
  color: inherit;
  background: none;
  border: none;
  padding: 0;
  font-family: 'Benton Sans', sans-serif;

  &:not([disabled]) {
    cursor: pointer;
  }
`

export const Button = styled(ButtonBase)<ButtonProps>`
  padding: 0.4em 1em;
  background: ${theme.color.text.base};
  display: inline-block;
  vertical-align: middle;
  position: relative;
  margin: 0;
  appearance: none;
  overflow: hidden;
  color: ${theme.color.text.light};
  text-align: center;
  transition: opacity 0.3s;
  text-decoration: none;
  ${label.large}

  ${mq.medium} {
    padding: 1.6em 2em;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    opacity: 0.18;
    transition:
      0.4s left,
      0.4s width 0.2s,
      0.4s transform-origin;
    transition-timing-function: ${theme.ease.inOut};
    transform: skew(-45deg);
    transform-origin: 0 0;
    background: currentColor;
  }

  &:not([disabled]) {
    a:hover &,
    &:focus &,
    &:hover,
    &:focus {
      &::before {
        left: 100%;
        width: 100%;
        transform-origin: 0 100%;
        transition:
          0.4s width,
          0.4s left 0.2s,
          0.4s transform-origin 0.2s;
      }
    }
  }

  &[disabled] {
    opacity: 0.5;
  }

  ${props =>
    props.primary &&
    css`
      background: ${theme.color.accent.primary};
    `}

  ${props =>
    props.wide &&
    css`
      ${mq.medium} {
        padding: 1.5em 7em 1.6em;
      }

      ${mq.smallOnly} {
        display: block;
        width: 100%;
        padding-top: 1.6em;
        padding-bottom: 1.6em;
      }
    `}

  ${props =>
    props.block &&
    css`
      display: block;
      width: 100%;
      padding: 1.8em;
    `}

  ${props =>
    props.inverted &&
    css`
      color: ${theme.color.text.base};
      background: ${theme.color.text.light};
    `}

  ${props =>
    props.invisible &&
    css`
      background: transparent;
    `}

  ${props =>
    props.outline &&
    css`
      border: 2px solid currentColor;
      background: transparent;

      ${props.wide &&
      css`
        ${mq.medium} {
          padding: 1.3em 3.4em 1.4em;
        }
      `}
    `}
`

export const ButtonLink = styled(Button).attrs({ as: 'a' })<ButtonProps>`
  text-decoration: none;
` as unknown as IStyledComponent<
  'web',
  ButtonProps & { href?: string; target?: string }
>

export const ButtonExternalLink = styled(Button).attrs({
  as: 'a',
})<ButtonProps>`
  text-decoration: none;
` as unknown as IStyledComponent<
  'web',
  ButtonProps & { href?: string; target?: string }
>
