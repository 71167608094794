import { FC } from 'react'

import { Svg } from '~/components/svg'

export const EyeOpen: FC = props => (
  <Svg {...props} viewBox="0 0 24 24">
    <path
      d="M3 12.2a8.803 8.803 0 0 1 17.31 0A8.803 8.803 0 0 1 3 12.2Zm8.655 4a4 4 0 1 0 0-8 4 4 0 0 0 0 8Zm0-1.6a2.4 2.4 0 1 1 0-4.8 2.4 2.4 0 0 1 0 4.8Z"
      fill="#B9B9C0"
    />
  </Svg>
)
