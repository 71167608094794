import styled from 'styled-components'

import { theme } from '~/styles/theme'
import { paragraph } from '~/styles/typography'

export const ErrorMessage = styled.span`
  ${paragraph.normal}
  display: block;
  font-weight: 500;
  line-height: 1;
  color: ${theme.color.accent.error};
`
