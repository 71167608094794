import { config } from './utils/config'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

export const commonSentryOptions = {
  dsn: SENTRY_DSN,
  enabled: process.env.NODE_ENV === 'production',
  environment: config.environment,
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
}
