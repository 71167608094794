import type { FC, PropsWithChildren } from 'react'
import { createContext, useContext, useState } from 'react'

import type {
  LibraryItemClassTypeEnum,
  LibraryItemFilter,
} from '~/@types/schemas'

interface VodContextType {
  filters: LibraryItemFilter
  updateFilters: (newFilters: Partial<LibraryItemFilter>) => void
  classTypeFilters: LibraryItemClassTypeEnum[]
  setClassTypeFilters: (newFilters: LibraryItemClassTypeEnum[]) => void
}

const VodContext = createContext<VodContextType | null>(null)

export const VodContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [filters, setFilters] = useState<LibraryItemFilter>(null)

  const [classTypeFilters, setClassTypeFilters] = useState<
    LibraryItemClassTypeEnum[]
  >([])

  const updateFilters = (newFilters: Partial<LibraryItemFilter>) =>
    setFilters(previousFilters => ({
      ...previousFilters,
      ...newFilters,
    }))

  return (
    <VodContext.Provider
      value={{ filters, updateFilters, classTypeFilters, setClassTypeFilters }}
    >
      {children}
    </VodContext.Provider>
  )
}

export const useVodContext = () => {
  const context = useContext(VodContext)

  return context
}
