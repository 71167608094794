import { FC } from 'react'

import { Button } from '~/components/button'
import { strings } from '~/lang/en'

import { Form } from '../styled'

type Props = {
  onConfirm: () => void
}

export const ResetPasswordSuccess: FC<Props> = ({ onConfirm }) => (
  <Form as="div">
    <p>{strings.login.forgot_password.confirmation}</p>

    <Button inverted block onClick={onConfirm}>
      Close
    </Button>
  </Form>
)
