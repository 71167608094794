export const config = {
  rootUrl: process.env.NEXT_PUBLIC_ROOT_URL,
  apiUrl: process.env.NEXT_PUBLIC_API_URL,
  firebase: {
    apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
    authDomain: `${process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID}.firebaseapp.com`,
    databaseURL: `https://${process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID}.firebaseio.com`,
    projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
    storageBucket: `${process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID}.appspot.com`,
    messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_SENDER_ID,
    appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
    measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
  },
  manualSubscriptions: process.env.NEXT_PUBLIC_MANUAL_SUBSCRIPTIONS === 'on',
  version:
    process.env.NEXT_PUBLIC_SHOW_VERSION !== 'off'
      ? process.env.appVersion
      : '',
  appId: {
    ios: process.env.NEXT_PUBLIC_IOS_APP_ID,
    android: process.env.NEXT_PUBLIC_ANDROID_APP_ID,
  },
  allowDebug: process.env.NEXT_PUBLIC_ALLOW_DEBUG === 'on',
  environment: process.env.NEXT_PUBLIC_API_URL?.replace(
    /https:\/\/api\.(?<environment>[a-z]+)\.athome\.barrys\.com\/graphql/u,
    '$1'
  ),
  cookieSettings: {
    maxAge: 30 * 24 * 60 * 60,
    path: '/',
  },
  membershipsLink: process.env.NEXT_PUBLIC_MEMBERSHIPS_LINK,
  googleTagManagerID: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID,
  fbDomainVerification: process.env.NEXT_PUBLIC_FB_DOMAIN_VERIFICATION,
  mmebershipsFAQLink: process.env.NEXT_PUBLIC_MEMBERSHIPS_FAQ_LINK,
  liveClassCaptions: {
    enabled:
      process.env.NEXT_PUBLIC_LIVE_CLASS_CAPTIONS === 'on' &&
      process.env.NEXT_PUBLIC_LIVE_CLASS_CAPTIONS_SERVER_URL,
    serverUrl: process.env.NEXT_PUBLIC_LIVE_CLASS_CAPTIONS_SERVER_URL,
  },
  settingsVisible: process.env.NEXT_PUBLIC_SETTINGS === 'on',
  dynamicLinkUrl: process.env.NEXT_PUBLIC_DYNAMIC_LINK_URL,
  music: {
    feedFm: {
      token: process.env.NEXT_PUBLIC_FEED_FM_TOKEN,
      secret: process.env.NEXT_PUBLIC_FEED_FM_SECRET,
    },
    apple: {
      teamId: process.env.NEXT_PUBLIC_APPLE_TEAM_ID,
      keyId: process.env.NEXT_PUBLIC_APPLE_KEY_ID,
      privateKey: process.env.NEXT_PUBLIC_APPLE_PRIVATE_KEY,
    },
  },
}
