import { InputHTMLAttributes, forwardRef, useState } from 'react'

import { IconError } from '~/components/icons/error'
import { EyeClosed } from '~/components/icons/eye-closed'
import { EyeOpen } from '~/components/icons/eye-open'

import {
  Label,
  InputElement,
  ErrorMessage,
  Toggle,
  InputProps,
} from '../styled'

type Props = InputHTMLAttributes<HTMLInputElement> &
  InputProps & {
    value?: string
    error?: string
    hideErrorMessage?: boolean
    label: string
  }

export const Input = forwardRef<HTMLInputElement, Props>(
  ({ error, value, type, label, hideErrorMessage, ...props }, ref) => {
    const [showValue, setShowValue] = useState(false)

    return (
      <>
        <Label>
          <InputElement
            placeholder={label}
            value={value}
            type={showValue ? 'text' : type}
            hasError={!!error}
            hasRightAddon={type === 'password'}
            ref={ref}
            {...props}
          />
          <span>{label}</span>
          {type === 'password' && (
            <Toggle type="button" onClick={() => setShowValue(!showValue)}>
              {showValue ? <EyeClosed /> : <EyeOpen />}
            </Toggle>
          )}
        </Label>
        {!!error && !hideErrorMessage && (
          <ErrorMessage>
            <IconError /> {error}
          </ErrorMessage>
        )}
      </>
    )
  }
)
