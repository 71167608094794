import styled, { css, keyframes } from 'styled-components'

import {
  Button,
  ButtonExternalLink as ButtonExternalLinkBase,
} from '~/components/button'
import { Container } from '~/components/container'
import { IconBarrysX } from '~/components/icons/barrys-x'
import { BarrysXChavron } from '~/components/icons/barrysx-chevron'
import { IconExternal } from '~/components/icons/external'
import { Footer as FooterBase } from '~/components/layout/footer'
import { Overlay, styleReset } from '~/styles/misc'
import { mq } from '~/styles/mq'
import { theme } from '~/styles/theme'
import { font, label, paragraph, heading } from '~/styles/typography'

export const View = styled.div<{ isLoginVisible: boolean }>`
  ${Overlay};
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  --login-spacing: 2rem;

  ${mq.medium} {
    --login-spacing: 10vmin;
  }

  ${props =>
    props.isLoginVisible &&
    css`
      justify-content: flex-end;
      align-items: flex-start;
      padding: var(--login-spacing);
    `};
`

export const BAHIcon = styled(IconBarrysX)`
  height: auto;

  g:last-child {
    fill: ${theme.color.accent.primary};
  }
`

const slidingBlock = keyframes`
  50% {
    left: 0;
    width: 100%;
  }
  100% {
    left: 100%;
    width: 0;
  }
`

const hideLogo = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`

const slideUpText = keyframes`
  from {
    clip-path: polygon(0 -100%, 100% -100%, 100% 0, 0 0);
    transform: translateY(100%);
  }
  to {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    transform: none;
  }
`

const slideUpAnimation = ({
  duration,
  delay = 0,
  timing = theme.ease.out,
}) => css`
  animation: ${duration}s ${slideUpText} ${delay}s ${timing} forwards;
  clip-path: polygon(0 -100%, 100% -100%, 100% 0, 0 0);
  transform: translateY(100%);
`

export const Barrys = styled.span`
  ${slideUpAnimation({ duration: 0.7 })}
  display: block;
  width: 0.9em;
  margin: 0.008em 0;
  overflow: hidden;

  svg {
    display: block;
    flex-shrink: 0;
  }
`

export const AtHome = styled(Barrys)`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  width: 0.1em;
  animation-delay: 0.2s;
  color: ${theme.color.accent.primary};
`

export const Heading = styled.h2`
  ${heading.h3}
  margin: 0 0 2rem;
  text-transform: none;
`

export const Title = styled.h3`
  ${heading.h5};
  text-transform: none;
`

export const Description = styled.div`
  position: relative;
  width: 100%;
  margin: 0 0 4rem;
  line-height: 1.5;
  flex-shrink: 0;

  p {
    max-width: 100% !important;
    font-size: inherit !important;
  }

  a {
    display: block;
    font-weight: bold;
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
`

export const Logo = styled.span<{
  isVideoVisible: boolean
  isLoginVisible: boolean
}>`
  display: flex;
  position: relative;
  align-items: center;
  overflow: hidden;
  padding: 0 0.08em;
  pointer-events: none;
  font-size: 50vmin;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background: currentColor;
  }

  ${props =>
    props.isVideoVisible &&
    css`
      &::after {
        animation: 1s ${slidingBlock} ease-in-out forwards;
      }

      ${Barrys}, ${AtHome} {
        transform: none;
        clip-path: none;
        animation: 10ms ${hideLogo} 0.5s linear forwards;
      }
    `}

  ${props =>
    props.isLoginVisible &&
    css`
      ${slideUpAnimation({ duration: 0.7 })}
      padding: 0;
      font-size: 50vmin;

      ${Barrys}, ${AtHome} {
        animation: none;
        transform: none;
        clip-path: none;
        font-size: 1.03em;
        color: inherit;
      }
    `}
`

export const Video = styled.video<{ isVisible: boolean }>`
  ${Overlay};
  left: -100%;
  object-fit: cover;
  opacity: 0.01;
  transition: 0.7s left 0.7s;

  ${props =>
    props.isVisible &&
    css`
      left: 0;
      opacity: 0.6;
    `}
`

export const Intro = styled.p`
  ${slideUpAnimation({ duration: 0.7, delay: 0.1 })}
  ${paragraph.extraLarge}
    font-family: ${font.headings};
  max-width: 28em;
`

export const LoginButton = styled(Button)`
  ${slideUpAnimation({ duration: 0.55, delay: 0.4 })}
  font-size: 1.4rem;
  padding: 1.85em 7em;
`

export const ButtonContainer = styled.div`
  margin-top: 0.5em;

  ${mq.medium} {
    margin-top: 2.5em;
  }
`

const offcanvasValue = 'calc(-5vmin - 7rem)'

const slideDown = keyframes`
  from {
    transform: translateY(${offcanvasValue});
  }
  to {
    transform: none;
  }
`

export const BarrysChevron = styled(BarrysXChavron)`
  margin-right: auto;
  transform: translateY(${offcanvasValue});
  animation: 0.8s ${slideDown} ${theme.ease.out} forwards;
`

export const Header = styled.header`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0 0 auto;
  font-size: 4.5rem;

  ${mq.medium} {
    margin: -5vmin 0 auto;
  }

  a,
  button {
    margin-left: 4rem;
    transform: translateY(${offcanvasValue});
    animation: 0.65s ${slideDown} 0.15s ${theme.ease.out} forwards;

    ${mq.smallOnly} {
      width: auto;
      padding: 1.2em 1.8em;
      font-size: 1.2rem;
    }
  }
`

export const ExternalIcon = styled(IconExternal)`
  margin: -0.8em -0.2em -0.5em 0.1em;
  font-size: 2.1em;
`

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: white;
  background-color: black;

  &::before,
  &::after {
    content: '';
    display: block;
    flex: 2;
  }

  &::after {
    flex: 3;
  }

  p {
    ${paragraph.large}
    text-align: center;
    max-width: 16em;
  }
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 34rem;
`

export const ButtonLink = styled.button`
  ${styleReset};
  cursor: pointer;
  font-weight: bold;
`

export const ForgotPasswordLink = styled.button`
  ${styleReset}
  ${label.normal}
  display: block;
  text-align: right;
  text-decoration: none;
  margin: 1rem 0 3.5rem auto;
  cursor: pointer;
`

export const Inner = styled.div`
  width: 100%;
`

export const MobileContainer = styled.div`
  ${mq.smallOnly} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    padding: 0 2rem;
    font-size: 1.8rem;
    color: ${theme.color.neutral[200]};
    text-align: center;

    & ~ * {
      display: none !important;
    }

    svg {
      font-size: 18rem;
      height: auto;
      margin-bottom: 3rem;
      color: white;

      g:not(:first-child) {
        color: ${theme.color.accent.primary};
      }
    }
  }

  ${mq.medium} {
    display: none;
  }
`

const footerIn = keyframes`
  from {
    transform: translateY(3rem);
    opacity: 0;
  }
  to {
    transform: translateY(0.5rem);
    opacity: 1;
  }
`

export const Footer = styled(FooterBase)`
  opacity: 0;
  animation: 0.7s ${footerIn} forwards;
  pointer-events: none;
  color: ${theme.color.neutral[200]};

  a {
    pointer-events: all;
  }

  ${Container} {
    padding: var(--login-spacing);
    padding-bottom: 0;
    padding-top: 0;
  }

  ${mq.medium} {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }
`

export const ButtonExternalLink = styled(ButtonExternalLinkBase)`
  margin-top: 4rem;
  padding-top: 1em;
  padding-bottom: 1em;
`

export const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  margin-bottom: 2rem;

  span {
    flex: 1;
    padding-left: 1em;
  }
`
