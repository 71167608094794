import { destroyCookie, parseCookies, setCookie } from 'nookies'

import type { NextPageContext } from '~/@types/models'

import { config } from '../config'

export const createTokenManager = (ctx?: NextPageContext) => {
  const parsedTokens = parseCookies(ctx)
  let accessToken = parsedTokens.accessToken
  let refreshToken = parsedTokens.refreshToken

  return {
    get: () => ({ accessToken, refreshToken }),
    setAccessToken: (value: string) => {
      accessToken = value
      setCookie(ctx, 'accessToken', value, config.cookieSettings)
    },
    setRefreshToken: (value: string) => {
      refreshToken = value
      setCookie(ctx, 'refreshToken', value, config.cookieSettings)
    },
    destroyTokens: () => {
      accessToken = ''
      refreshToken = ''
      destroyCookie(ctx, 'accessToken')
      destroyCookie(ctx, 'refreshToken')
    },
  }
}
