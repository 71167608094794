import { FC, SVGProps } from 'react'
import styled from 'styled-components'

const SvgElement = styled.svg`
  display: inline-block;
  vertical-align: middle;
  width: 1em;
  height: 1em;
`

export const Svg: FC<Omit<SVGProps<SVGElement>, 'ref'>> = ({
  children,
  viewBox = '0 0 87 87',
  ...rest
}) => (
  <SvgElement xmlns="http://www.w3.org/2000/svg" viewBox={viewBox} {...rest}>
    {children}
  </SvgElement>
)
