import { css } from 'styled-components'

import { mq } from './mq'

const font = {
  base: '"Benton Sans", sans-serif',
  headings: 'Antenna, sans-serif',
}

const allHeadingsStyle = css`
  font-family: ${font.headings};
  font-weight: 700;
  text-transform: uppercase;
`

const heading = {
  all: allHeadingsStyle,
  title: css`
    ${allHeadingsStyle};
    font-size: 4.8rem;

    ${mq.medium} {
      font-size: 5.6rem;
    }
  `,
  h1: css`
    ${allHeadingsStyle};
    font-size: 3.6rem;

    ${mq.medium} {
      font-size: 4.8rem;
    }
  `,
  h2: css`
    ${allHeadingsStyle};
    font-size: 3rem;

    ${mq.medium} {
      font-size: 3.6rem;
    }
  `,
  h3: css`
    ${allHeadingsStyle};
    font-size: 2.4rem;

    ${mq.medium} {
      font-size: 2.8rem;
    }
  `,
  h4: css`
    ${allHeadingsStyle};
    font-size: 1.8rem;

    ${mq.medium} {
      font-size: 2.4rem;
    }
  `,
  h5: css`
    ${allHeadingsStyle};
    font-size: 1.6rem;

    ${mq.medium} {
      font-size: 1.8rem;
    }
  `,
}

const label = {
  normal: css`
    ${allHeadingsStyle}
    font-size: 1.1rem;
  `,
  large: css`
    ${allHeadingsStyle}
    font-size: 1.4rem;
  `,
}

const paragraph = {
  small: css`
    font-size: 1.2rem;
    line-height: 1.4;
  `,
  normal: css`
    font-size: 1.4rem;
    line-height: 1.4;
  `,
  demi: css`
    font-size: 1.6rem;
    line-height: 1.4;
  `,
  large: css`
    font-size: 1.8rem;
    line-height: 1.4;
  `,
  extraLarge: css`
    font-size: 1.6rem;
    line-height: 1.5;

    ${mq.medium} {
      font-size: 2.2rem;
    }
  `,
}

export { font, heading, paragraph, label }
