import { gql } from '@apollo/client'

import { Fragments } from './fragments'

export const LOGIN = gql`
  mutation SignIn($username: String!, $password: String!) {
    signIn(input: { username: $username, password: $password }) {
      user {
        ...UserFields
      }
      session {
        ...SessionFields
      }
    }
  }
  ${Fragments.user}
  ${Fragments.session}
`

export const REFRESH_TOKEN = gql`
  mutation RefreshToken($refreshToken: String!) {
    refreshToken(refreshToken: $refreshToken) {
      user {
        ...UserFields
      }
      session {
        ...SessionFields
      }
    }
  }
  ${Fragments.user}
  ${Fragments.session}
`

export const RESET_PASSWORD = gql`
  mutation ResetPassword($email: String!) {
    resetPassword(input: { email: $email }) {
      email
    }
  }
`

export const CONNECT_CLIENT = gql`
  mutation ConnectClient($input: ConnectClientInput!) {
    connectClient(input: $input) {
      accessToken
      twilioRoomName
      liveSession {
        id
        url
        status
        rooms {
          name
          roomIndex
        }
      }
      room {
        ...LiveSessionRoomFields
      }
    }
  }
  ${Fragments.room}
`

export const SEND_ROOM_MESSAGE = gql`
  mutation SendRoomMessage($input: SendRoomMessageInput!) {
    sendRoomMessage(input: $input)
  }
`

export const SEND_SESSION_RATING = gql`
  mutation CreateRatingForClassSession(
    $ratingQ1: RatingLevel!
    $ratingQ2: RatingLevel!
    $additionalComments: String!
    $classSessionID: ID!
  ) {
    createRatingForClassSession(
      input: {
        ratingQ1: $ratingQ1
        ratingQ2: $ratingQ2
        additionalComments: $additionalComments
        classSessionID: $classSessionID
      }
    )
  }
`

export const SEND_LIBRARY_ITEM_RATING = gql`
  mutation CreateRatingForLibraryItem(
    $ratingQ1: RatingLevel!
    $ratingQ2: RatingLevel!
    $additionalComments: String!
    $libraryItemID: ID!
  ) {
    createRatingForLibraryItem(
      input: {
        ratingQ1: $ratingQ1
        ratingQ2: $ratingQ2
        additionalComments: $additionalComments
        libraryItemID: $libraryItemID
      }
    )
  }
`

export const FINISH_SESSION = gql`
  mutation FinishSession($id: ID!) {
    finishSession(input: { sessionID: $id })
  }
`

export const CREATE_BOOKMARK = gql`
  mutation CreateBookmarkForLibraryItem($libraryItemID: ID!) {
    createBookmarkForLibraryItem(libraryItemID: $libraryItemID)
  }
`

export const REMOVE_BOOKMARK = gql`
  mutation RemoveBookmarkByLibraryItemID($libraryItemID: ID!) {
    removeBookmarkByLibraryItemID(libraryItemID: $libraryItemID)
  }
`

export const TOGGLE_RED_FILTER = gql`
  mutation ToggleRedFilter($roomID: ID!, $value: Boolean!) {
    toggleRedFilter(input: { roomID: $roomID, value: $value })
  }
`

export const FORCE_VIDEO_OFF = gql`
  mutation ForceVideoOff($roomID: ID!, $userID: ID!, $value: Boolean!) {
    forceVideoOff(input: { roomID: $roomID, userID: $userID, value: $value })
  }
`

export const FORCE_SOUND_OFF = gql`
  mutation ForceSoundOff($roomID: ID!, $userID: ID!, $value: Boolean!) {
    forceSoundOff(input: { roomID: $roomID, userID: $userID, value: $value })
  }
`

export const BAN_USER_FROM_SESSION = gql`
  mutation BanUserFromSession($roomID: ID!, $userID: ID!, $value: Boolean!) {
    banUserFromSession(
      input: { roomID: $roomID, userID: $userID, value: $value }
    )
  }
`

export const CHANGE_VIDEO_PREFERENCE = gql`
  mutation ChangeVideoPreference($roomID: ID!, $value: VideoPreferenceEnum!) {
    changeVideoPreference(input: { roomID: $roomID, value: $value })
  }
`

export const SAVE_VIDEO_ANALYTICS = gql`
  mutation SaveVideoAnalyticsEvent(
    $idempotencyKey: String!
    $playedTime: Float!
    $libraryItemID: ID!
  ) {
    saveVideoAnalyticsEvent(
      input: {
        idempotencyKey: $idempotencyKey
        playedTime: $playedTime
        libraryItemID: $libraryItemID
      }
    )
  }
`

export const CHANGE_ROOM_TO_PREPARATION = gql`
  mutation ChangeRoomStatusToPreparation($roomID: ID!) {
    changeRoomStatusToPreparation(input: { roomID: $roomID })
  }
`

export const CHANGE_ROOM_TO_ACTIVE = gql`
  mutation ChangeRoomStatusToActive($roomID: ID!) {
    changeRoomStatusToActive(input: { roomID: $roomID })
  }
`

export const DISCONNECT_CLIENT = gql`
  mutation DisconnectClient($roomID: ID!) {
    disconnectClient(input: { roomID: $roomID })
  }
`

export const CREATE_LIVE_SESSION = gql`
  mutation CreateLiveSession($classSessionID: String!) {
    createLiveSession(input: { classSessionID: $classSessionID }) {
      id
    }
  }
`

export const CANCEL_USER_MEMBERSHIP = gql`
  mutation CancelUserMembership($input: CancelUserMembershipInput!) {
    cancelUserMembership: cancelUserMembership_v2(input: $input) {
      ...MembershipFields
    }
  }
  ${Fragments.membership}
`

export const MUTE_ALL = gql`
  mutation MuteAll($input: MuteAllInput!) {
    muteAll(input: $input)
  }
`

export const START_MUSIC_PLAYBACK = gql`
  mutation StartMusicPlayback($input: StartMusicPlaybackInput!) {
    startMusicPlayback(input: $input) {
      isSuccessful
      message
    }
  }
`

export const UPDATE_MUSIC_PLAYBACK_TIMES = gql`
  mutation UpdateMusicPlaybackTimes($input: UpdateMusicPlaybackInput!) {
    updateMusicPlaybackTimes(input: $input) {
      isSuccessful
    }
  }
`

export const STORE_DEVICE_INFO = gql`
  mutation StoreDeviceInfo($input: StoreDeviceInfoInput!) {
    storeDeviceInfo(input: $input) {
      id
    }
  }
`

export const CREATE_USER = gql`
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      user {
        ...UserFields
      }
      session {
        ...SessionFields
      }
    }
  }
  ${Fragments.user}
  ${Fragments.session}
`

export const UPDATE_USER = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      user {
        ...UserFields
      }
    }
  }
  ${Fragments.user}
`

export const REPORT_GYMPASS_EVENT = gql`
  mutation ReportGympassEvent($eventType: GympassEventEnum!) {
    reportGympassEvent(eventType: $eventType)
  }
`

export const ACCEPT_FRIENDSHIP = gql`
  mutation AcceptFriendship($friendshipId: ID!) {
    acceptFriendship(friendshipID: $friendshipId) {
      ...FriendshipFields
    }
  }
  ${Fragments.friendship}
`

export const DECLINE_FRIENDSHIP = gql`
  mutation DeclineFriendship($friendshipId: ID!) {
    declineFriendship(friendshipID: $friendshipId) {
      ...FriendshipFields
    }
  }
  ${Fragments.friendship}
`

export const REQUEST_FRIENDSHIP = gql`
  mutation RequestFriendship($input: RequestFriendshipInput!) {
    requestFriendship(input: $input) {
      ...FriendshipFields
    }
  }
  ${Fragments.friendship}
`

export const REMOVE_FRIENDSHIP = gql`
  mutation RemoveFriendship($friendshipId: ID!) {
    removeFriendship(friendshipID: $friendshipId)
  }
`

export const CANCEL_FRIENDSHIP_REQUEST = gql`
  mutation CancelFriendshipRequest($input: RequestFriendshipInput!) {
    cancelFriendshipRequest(input: $input) {
      friend {
        id
        firstName
        lastName
        username
        pictureUrl
        friendship {
          id
          status
          requester {
            id
          }
        }
      }
    }
  }
  ${Fragments.friendship}
`

export const CREATE_REPORT = gql`
  mutation CreateReport($input: CreateReportInput!) {
    createReport(input: $input) {
      id
    }
  }
`
