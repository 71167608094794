import type { FC, PropsWithChildren } from 'react'

import { Footer } from './footer'
import { PageHeader } from './header'

export const Layout: FC<
  PropsWithChildren<{
    readonly invertedHeader?: boolean
    readonly invertedFooter?: boolean
    readonly isMenuHidden?: boolean
  }>
> = ({ invertedHeader, invertedFooter, isMenuHidden, children }) => (
  <>
    <PageHeader inverted={invertedHeader} isMenuHidden={isMenuHidden} />

    {children}

    <Footer inverted={invertedFooter} />
  </>
)
